import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Interactive Content is the Future",
  },
  "header": {
    "title": <><span>Interactive Expertise</span> is the Future</>,
    "description": <>Join the movement to promote Logic-Based Decision-Making through Interactive Content.</>,
    "app": "/apps/61f9bb9f833873193c2a58c6"
  },
  "main": [
    {
      "title": "Become a Logic Creator",
      "description": <>Earn more as a creator with Logictry promoting Logic-Based Decision-Making.</>,
      "app": "/apps/67616a6255020a92ee60bc7a"
    },
    {
      "title": "Become a Channel Partner",
      "description": <>Refer our technology to customers and earn</>,
      "app": "/apps/676168bd55020a92ee60bc3b"
    },
    {
      "title": "Sign the Logic Proclamation",
      "description": <>Commit to Logic-Based Decision-Making for the Next Generation</>,
      "app": "/apps/67574378b954a7f950f0338b"
    },
    {
      "title": "Join the Logic Circle",
      "description": <>Here is more information</>,
      "app": "/apps/67c7c49c1552b9531a9d98b8"
    },
    {
      "title": "High-level Overview",
      "description": <>Here is more information</>,
      "app": "/apps/67c7c1931552b9531a9d9846"
    },
    {
      "title": "Learn More",
      "description": <>Here is more information</>,
      "app": "/apps/67c7c2371552b9531a9d987a"
    },
  ],
}

const CreatorsHome = () => {
  return (
    <Template content={content} />
  )
};
export default CreatorsHome;
